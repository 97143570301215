import Api from 'utils/Api';
import Auth from 'utils/Auth';

const signin = (data) => {
  const response = Api.post('/auth/login/', data);
  return response;
};
const employeeLogin = (data) => {
  const response = Api.post('/capture/legacy-employees/login/', data);
  return response;
};

const verifyUserOtp = (data) => {
  const response = Api.post(`/capture/legacy-employees/verify-otp/`, data);
  return response;
};

const signup = (data) => {
  const response = Api.post('/users/register/', data);
  return response;
};

const forgotPassword = (data) => {
  const response = Api.post('/auth/reset-password/', data);
  return response;
};

const verifyToken = (data) => {
  const response = Api.post('/auth/verify-token/', data);
  return response;
};

const updateUser = (data) => {
  const response = Api.post('/auth/set-password/', data);
  return response;
};

const createUser = (data) => {
  const response = Api.post('/users/register/', data);
  return response;
};

export function getUsers({ queryKey }) {
  const [, queryParams] = queryKey;
  return Api.get(`/users/`, {
    params: queryParams
  });
}

export function activateAdmin({ queryKey }) {
  const [, queryParams] = queryKey;
  return Api.get(`/users/${queryParams.id}/activate/`);
}

export function deactivateAdmin({ queryKey }) {
  const [, queryParams] = queryKey;
  return Api.get(`/users/${queryParams.id}/suspend/`);
}

const getCurrentUser = () => {
  const decodedToken = Auth.getDecodedJwt();
  const token = Auth.getToken();

  if (token) {
    Api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete Api.defaults.headers.common.Authorization;
  }

  const response = Api.get(`/auth/users/${decodedToken.user_id}/`);
  return response;
};

const AuthApis = {
  signin,
  signup,
  forgotPassword,
  updateUser,
  verifyToken,
  getCurrentUser,
  createUser,
  activateAdmin,
  deactivateAdmin,
  employeeLogin,
  verifyUserOtp
};

export default AuthApis;
