import { Box } from '@mui/material';
import { Fragment, Suspense } from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import routes from './routes';

export default function AppRoutes() {
  const renderRoute = ({ component: Component, ...route }) => {
    const Guard = route.guard ? route.guard : Fragment; // to set the AuthGuard
    const Layout = route.layout ? Fragment : Fragment; // to set the APP Layout

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          <Layout>
            <Suspense
              fallback={
                <Box width="100vw" height="100vh" display="grid" sx={{ placeItems: 'center' }}>
                  <h1>Page Loading...</h1>
                </Box>
              }
            >
              <Guard>
                <Component />
              </Guard>
            </Suspense>
          </Layout>
        }
      />
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        {routes.map((route) => renderRoute(route))}
      </Routes>
    </Router>
  );
}
