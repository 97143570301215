import { useQuery } from 'react-query';

import Auth from 'utils/Auth';
import AuthApis from 'services/auth';

export default function useAuth() {
  const {
    data: { data } = {},
    isLoading,
    remove,
    refetch
  } = useQuery({
    queryKey: 'userDetails',
    queryFn: AuthApis.getCurrentUser,
    enabled: Boolean(Auth.isAuthenticated()),
    config: {
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100
    }
  });

  return {
    user: data,
    isLoading,
    isAuthenticated: Boolean(Auth.isAuthenticated()),
    refetchUser: refetch,
    clearUserData: remove
  };
}
