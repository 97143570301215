import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { AuthPath } from 'routes/paths';

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { isLoading, isAuthenticated, user } = useAuth();

  if (isLoading) {
    return (
      <Box display="grid" sx={{ placeItems: 'center' }} height="100vh" width="100vw">
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to={`/${AuthPath.LOGIN}`} />;
  }

  if (user?.id) {
    return <>{children}</>;
  }

  return null;
}
