// This file provides an interface for all our api services defined in the services file of each module.
import axios from 'axios';
import Auth from './Auth';

export const baseUrl = process.env.REACT_APP_BASE_API_URL;

const Api = axios.create({
  baseURL: baseUrl
});

Api.interceptors.request.use(
  (config) => {
    // This adds an authorization key to config object if a token exists.
    if (Auth.isAuthenticated()) {
      // eslint-disable-next-line
      config.headers.common['Authorization'] = `Bearer ${Auth.getToken()}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// This ensures that appropriate error message from the API are parsed to react query
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject((error.response && error.response.data) || error);
  }
);

export default Api;
