export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export const getValueOrNull = (value) => {
  if (!value) return '--';
  if (value === 'nan' || value === 'NAN') return '--';
  return value;
};
