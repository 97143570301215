const PATHS = {
  HOME: '/*',
  UI_DOCS: '/ui-docs',
  DASHBOARD: '/dashboard'
};

export const DASHBOARD_PATHS = {
  WORKBENCH: 'workbench',
  DIRECTORY: 'directory',
  TOP_DIRECTORY: 'directories',
  VERIFICATION: 'verification',
  ID_CARDS: 'id-cards',
  VISITORS: 'visitors',
  AUDIT: 'audit',
  SETTINGS: 'settings',
  USER_DATA_UPLOAD: 'user-upload'
};

export const AuthPath = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  USER_DETAIL: 'user-data/:id',
  USER_PHOTO_UPLOAD: 'user-data/:id/upload',
  SUCCESS_PAGE: 'user-data/:id/upload/success',
  USER_LOGIN: 'verify'
};

export default PATHS;
