import AuthGuard from 'guards/AuthGuards';
import { lazy } from 'react';
import Path from './paths';

const routes = [
  {
    path: Path.HOME,
    component: lazy(() => import('views/Home')),
    exact: true
  },
  {
    path: Path.UI_DOCS,
    component: lazy(() => import('views/UiDocs')),
    exact: true
  },
  {
    guard: AuthGuard,
    path: `${Path.DASHBOARD}/*`,
    component: lazy(() => import('views/dashboard')),
    exact: true
  }
];

export default routes;
