import { alpha } from '@mui/material/styles';

const PRIMARY = {
  lighter: '#FFD4D2',
  light: '#9A0002',
  main: '#9F1F17',
  dark: '#004AD7',
  darker: '#091A7A'
};

const SECONDARY = {
  lighter: '#CDCED9',
  light: '#30313F',
  main: '#272833',
  dark: '#393A4A',
  darker: ''
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#15692A',
  dark: '',
  darker: ''
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#0050C8',
  dark: '',
  darker: ''
};

const WARNING = {
  lighter: '#FFF5EA',
  light: '#E0B878',
  main: '#B98900',
  dark: '',
  darker: ''
};

const ERROR = {
  lighter: '#FEEFEF',
  light: '#F48989',
  main: '#804C00',
  dark: '',
  darker: '#9F4167'
};

const GREY = {
  disabled: '#E7E7ED',
  500_24: alpha('#919EAB', 0.24),
  50: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  A100: alpha('#919EAB', 0.08),
  A200: alpha('#919EAB', 0.16),
  A400: alpha('#919EAB', 0.32),
  A700: alpha('#919EAB', 0.56)
};
const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: GREY[500_24]
};

const palette = {
  ...COMMON,
  mode: 'light',
  text: {
    primary: '#393A4A',
    secondary: '#6B6C7E',
    disabled: '#A7A9BC',
    tertiary: '#323130',
    dark: '#212326',
    light: '#9DA0A7',
    main: '#272833'
  },
  background: { paper: '#fff', default: '#F8F7FA', neutral: GREY.disabled, inherit: '#F1F2F6' }
};

export default palette;
