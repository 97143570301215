import JWTDecode from 'jwt-decode';

const tokenName = '@mdm_token';

const setToken = (token) => {
  localStorage.setItem(tokenName, token);
};

const getToken = () => {
  return localStorage.getItem(tokenName);
};

const getDecodedJwt = () => {
  try {
    const token = getToken();
    return JWTDecode(token);
  } catch (e) {
    return {};
  }
};

const removeToken = () => {
  localStorage.removeItem(tokenName);
};

const logOut = () => {
  removeToken();
  window.location.replace('/login');
};

const isAuthenticated = () => {
  try {
    const decodedToken = getDecodedJwt();

    const { exp } = decodedToken;
    const currentTime = Date.now() / 1000;

    return exp > currentTime;
  } catch (e) {
    return false;
  }
};

// This might not be useful if the user role is not encoded with the jwt_token
const isAdmin = () => {
  try {
    const decodedToken = getDecodedJwt();
    const { roles } = decodedToken;
    return roles?.includes('ADMIN');
  } catch (error) {
    return false;
  }
};

const loggedInUser = () => {
  return getDecodedJwt();
};

const Auth = {
  isAuthenticated,
  getDecodedJwt,
  setToken,
  getToken,
  removeToken,
  logOut,
  isAdmin,
  loggedInUser
};

export default Auth;
