import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Toaster } from 'react-hot-toast';
import { styled } from '@mui/material/styles';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import AppRoutes from './routes';

const reactQueryConfig = {
  refetchOnWindowFocus: false,
  retry: false
};

const defaultOptions = {
  queries: reactQueryConfig,
  mutations: reactQueryConfig
};

const StyledToaster = styled(Toaster)(({ theme }) => ({
  border: 'none',
  padding: '5px 5px',
  borderRadius: '3px',
  fontFamily: "'Open Sans', sans-serif",
  ...theme.typography.body1
}));

const queryClient = new QueryClient({
  defaultOptions
});
const App = () => {
  const toastOptions = {
    position: 'top-right',
    duration: 10000
  };
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AppRoutes />
      </LocalizationProvider>
      <StyledToaster toastOptions={toastOptions} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
