import Card from './Card';
import Button from './Button';
import Typography from './Typography';
import IconButton from './IconButton';
import LoadingButton from './LoadingButton';
import Paper from './Paper';
// import Input from './Input';
import Table from './Table';
import Avatar from './Avatar';

export default function ComponentsOverrides(theme) {
  return {
    ...Card(theme),
    ...Button(theme),
    ...IconButton(theme),
    ...LoadingButton(theme),
    ...Typography(theme),
    ...Paper(theme),
    // ...Input(theme),
    ...Table(theme),
    ...Avatar(theme)
  };
}
